export const contentTableConfig = {
  propList: [
    { prop: 'name', label: '方案', minWidth: '150' , slotName: 'name'},
    {
      prop: 'shop',
      label: '关联门店',
      slotName: 'shop'
    },
    {
      prop: 'role',
      label: '关联角色',
      slotName: 'role'
    },
    {
      prop: 'type',
      label: '方案类型',
      slotName: 'type'
    },
    {
      prop: 'createTime',
      label: '创建时间',
      slotName: 'createTime',
      minWidth: '150'
    },
    {
      prop: 'updateTime',
      label: '最后修改时间',
      slotName: 'updateTime',
      minWidth: '150'
    },
    { label: '操作', minWidth: '250', slotName: 'handler', }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}

export const contentTableConfig2 = {
  propList: [
    { prop: 'name', label: '方案', minWidth: '150' , slotName: 'name'},
    {
      prop: 'bindCount',
      label: '关联人员',
      slotName: 'bindCount'
    },
    {
      prop: 'type',
      label: '方案类型',
      slotName: 'type'
    },
    {
      prop: 'createTime',
      label: '创建时间',
      minWidth: '150',
      slotName: 'createTime'
    },
    {
      prop: 'updateTime',
      label: '最后修改时间',
      minWidth: '150',
      slotName: 'updateTime'
    },
    { label: '操作', minWidth: '250', slotName: 'handler', }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}

export const contentTableConfig3 = {
  propList: [
    { prop: 'name', label: '方案', minWidth: '150' , slotName: 'name'},
    {
      prop: 'type',
      label: '方案类型',
      slotName: 'type'
    },
    {
      prop: 'bindCount',
      label: '关联角色数量',
      slotName: 'bindCount'
    },
    {
      prop: 'createTime',
      label: '创建时间',
      minWidth: '150',
      slotName: 'createTime'
    },
    { label: '操作', minWidth: '250', slotName: 'handler', }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}


export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: ''
  }
]
