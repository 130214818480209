<template>
  <div class="shop-dialog">
    <pageDialog
      title="选择门店"
      width="500px"
      :isShowFooter="true"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <div class="shop-coontent">
        <el-scrollbar height="300px">
          <el-checkbox-group v-model="checkList">
            <template v-for="item in shopList" :key="item.shopId">
              <el-checkbox
                :label="item.shopId"
              >
                {{ item.shopName }}</el-checkbox
              >
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>

      <template #footer>
        <div class="save-btn">
          <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick } from 'vue'
import {  getRankList } from '@/service/main/base'
import { distribution } from '@/service/main/score'
import { COMPANY_ID } from '@/common/constant'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getUnBindRuleShopList } from '@/service/main/commission'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    bindShop: {
      type: Array,
      default() {
        return []
      }
    },
    ruleId: {
      type: Number,
      default: -1
    },
    ruleKind: {
      type: Number,
      default: 1
    },
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible', 'refreshList'],
  setup(props, { emit }) {
    const route = useRoute()
    const checkList = ref([])
    const shopList = ref([])

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = async() => {
      let res = await distribution({
          targetIdList:checkList.value,
          ruleId:props.ruleId
        })
      if(res.code==0){
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emit('refreshList')
      }else {
        ElMessage.error('操作失败！')
      }
      changeDialogVisible(false)
    }


    const initPage = async () => {
      let type = props.ruleKind == 1?2:3
      const res = await getUnBindRuleShopList(props.ruleId,type)
      shopList.value = res.data
      checkList.value = props.bindShop

    }
    initPage()


    return {
      handleSaveClick,
      changeDialogVisible,
      checkList,
      shopList,
    }

  }
})
</script>

<style scoped lang="less">
.shop-coontent {
  &:deep(.el-checkbox-group) {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
}
</style>
