<template>
  <div class="list">
    <page-content :dataList="dataList" :contentTableConfig="contentTableConfig" :isButtonStyle="true">
      <template #type="scope">
        <template v-if="scope.row.type==1">
          门店业绩
        </template>
        <template v-else>
          员工业绩
        </template>
      </template>

      <template #shop="scope">
        <template v-if="scope.row.type==1">
          <div class="use-qty"> {{scope.row.bindCount}}</div>
        </template>
      </template>

      <template #role="scope">
        <template v-if="scope.row.type==2">
          <div class="use-qty"> {{scope.row.bindCount}}</div>
        </template>
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button @click="handleItemClick(scope.row)" plain size="mini" v-has="'score:rule:check'">详情</el-button>
          <el-button @click="handleUpdateClick(scope.row)" plain size="mini" v-has="'score:rule:edit'">编辑</el-button>
          <el-button @click="handleRemoveClick(scope.row)" plain size="mini" v-has="'score:rule:delete'">删除</el-button>
          <el-button @click="handleFenpeiClick(scope.row)" plain size="mini">分配</el-button>
        </div>
      </template>
      <template #pagination>
        <el-pagination v-model:currentPage="pageFrom.current" v-model:page-size="pageFrom.size"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </template>
    </page-content>

    <distribute @refreshList='refreshList' v-if="dialogVisible" :ruleId="curretnRule.id"
      @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible">
    </distribute>
    <roleDistribute @refreshList='refreshList' v-if="dialogRoleVisible" :ruleId="curretnRule.sid"
      @changeDialogVisible="changeRoleDialogVisible" :dialogVisible="dialogRoleVisible"></roleDistribute>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,watch } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from '../../config'
import { getScoreRuleList } from '@/service/main/score'
import { COMPANY_ID } from '@/common/constant'
import distribute from './cpns/distribute-dialog/distribute-dialog'
import roleDistribute from './cpns/role-distribute-dialog/role-distribute-dialog.vue'
import ruleFormVue from '@/views/main/reserve/site/base-ui/rule-form/rule-form.vue'
import { setRuleRuleState, deleteAchievementRule } from '@/service/main/commission'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    distribute,
    roleDistribute
  },
  setup() {
    const dataList = ref([])
    const router=useRouter()
    const dataCount = ref(0)

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const dialogRoleVisible = ref(false)
    const changeRoleDialogVisible = flag => {
      dialogRoleVisible.value = flag
    }

    const curretnRule = reactive({
      id: '',
      sid: ''
    })


    const refreshList = () => {
      initPage()
    }
    const ruleKind = ref()

    const handleFenpeiClick = (item) => {
      // console.log(item);
      ruleKind.value = item.type
      if (item.type == 1) {
        curretnRule.id = item.id
        dialogVisible.value = true
      } else {
        curretnRule.sid = item.id
        dialogRoleVisible.value = true
      }
    }

    const handleRemoveClick = async (item) => {
      popup('确认删除该方案吗?', async() => {
        const res = await deleteAchievementRule(item.id)
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          initPage()
        } else {
          ElMessage({
            message: '规则使用中,无法删除!',
            type: 'warning',
          })
        }
      })
    }


    const handleUpdateClick = item => {
      router.push({
        path: '/main/score/scoreEditrule',
        query: {
          ruleId: item.id,
          ruleKind: item.type
        }
      })
    }

    const handleItemClick = (item) => {
      // console.log(item);
      router.push({
        path: '/main/score/scoreEditrule',
        query: {
          ruleId: item.id,
          ruleKind: item.type,
          isview:true
        }
      })
    }
    const handleSizeChange = pagesize => {
      pageFrom.size = pagesize
    }

    const handleCurrentChange = page => {
      pageFrom.current = page
    }

    

    const pageFrom = reactive({
      current:1,
      size:10
    })
    
    watch(pageFrom, () => {
      initPage()
    }, {
      deep: true
    })
    const initPage = async () => {
      const res = await getScoreRuleList(pageFrom)
      dataList.value = res.data.list
      dataCount.value=Number(res.data.total)

    }
    initPage()

    return {
      handleItemClick,
      handleUpdateClick,
      handleRemoveClick,
      ruleKind,
      changeRoleDialogVisible,
      dialogRoleVisible,
      handleFenpeiClick,
      changeDialogVisible,
      dialogVisible,
      contentTableConfig,
      dataList,
      curretnRule,
      refreshList,
      pageFrom,
      dataCount,
      handleSizeChange,
      handleCurrentChange,
    }

  }
})
</script>

<style scoped lang="less">
.list {
  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }

  .use-qty {
    border-radius: 50%;
    background-color: rgb(240, 238, 238);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
}
</style>




