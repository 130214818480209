<template>
  <div class="top-content" v-has="'score:rule:add'">
    <el-button @click="handleItemClick(1)" type="primary"><el-icon><Plus /></el-icon>门店业绩</el-button>
    <el-button @click="handleItemClick(2)" type="primary"><el-icon><Plus /></el-icon>员工业绩</el-button>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router=useRouter()

    const handleItemClick=(type)=>{
        router.push({
          path:'/main/score/scoreAddrule',
          query:{
            type
          }
        })
    }

    return {
      handleItemClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content{
  display: flex;
  margin-bottom: 20px;
  &:deep(.el-button){
    margin-right: 10px;
  }
}
</style>




