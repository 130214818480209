<template>
  <div class="ticheng-topcontent" v-has="'shop:commission:add'">
    <el-button @click="handleItemClick(3,1)" type="primary">
      <el-icon>
        <Plus />
      </el-icon>服务提成
    </el-button>
    <el-button @click="handleItemClick(4,2)" type="primary">
      <el-icon>
        <Plus /> </el-icon
      >卡项提成
    </el-button>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router = useRouter()
    const handleItemClick = (type,ruleKind) => {
      router.push({
        path: '/main/score/scoreAddTicheng',
        query: {
          type,
          isview: false,
          ruleKind
        }
      })
    }

    return {
      handleItemClick
    }

  }
})
</script>

<style scoped lang="less">
.ticheng-topcontent {
  margin-bottom: 20px;

  &:deep(.el-button) {
    margin-right: 10px;
  }
}
</style>
