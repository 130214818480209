<template>
  <div class="add-rank-dialog">
    <page-dialog :width="25" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="关联门店">
      <!-- <div class="search">
        <el-input size="small"  @input="handleChange" prefix-icon="search" v-model="searchValue" placeholder="关键字" />
      </div> -->
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
          <div>状态</div>
        </div>
        <el-scrollbar height="200px">
          <el-checkbox-group v-model="checkList">
            <template :key="item" v-for="item in optDataList">
              <el-checkbox :label="item.shopId">
                <div class="item">
                  <div class="left">
                    <div class="name">{{ item.shopName }}</div>
                  </div>
                  <div class="right">
                    <el-tag v-if="checkList.some(ele => ele == item.shopId)" size="small" class="ml-2" type="success">
                      已分配</el-tag>
                    <el-tag v-else size="small" class="ml-2" type="info"> 未分配</el-tag>
                  </div>
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
      <div class="save-btn">
        <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { getUnBindRuleShopList} from '@/service/main/commission'
import { getScrollRuleDetail,distribution } from '@/service/main/score'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    ruleId: {
      type: Number,
      default: -1
    },
    ruleKind: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshList'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }


    const handleChange = value => {

    }

    const checkList = ref([])

    const ruleType = computed(() => props.ruleKind)
    const optDataList = ref([])

    const initPage = async () => {
      const detailres = await getScrollRuleDetail(props.ruleId)
      checkList.value = detailres.data.correlationIds
      let res = await getUnBindRuleShopList(props.ruleId,1)
      optDataList.value = res.data
    }
    initPage()

    const handleSaveClick = async() => {
      let res = await distribution({
          targetIdList:checkList.value,
          ruleId:props.ruleId
        })
      if(res.code==0){
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emit('refreshList')
      }else {
        ElMessage.error('操作失败！')
      }
      changeDialogVisible(false)
    }


    const searchValue = ref('')

    return {
      optDataList,
      ruleType,
      handleChange,
      searchValue,
      changeDialogVisible,
      checkList,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .save {
    &:deep(.el-button) {
      width: 100%;
    }
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 350px;

    .el-dialog__body {
      padding-bottom: 10px !important;
    }
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -15px;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);
      font-size: 12px;

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-checkbox) {
      display: flex;
      align-items: center;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;


          }
        }
      }
    }
  }
  .save-btn{
    display: flex;
    justify-content: flex-end;
  }
}
</style>




