<template>
  <div class="rule-lsit">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="业绩规则" name="first">
          <topContent></topContent>
          <list></list>
        </el-tab-pane>
        <el-tab-pane label="个人提成规则" name="second">
          <tichengTopcontent></tichengTopcontent>
          <tichengList></tichengList>
        </el-tab-pane>
        <el-tab-pane label="门店提成规则" name="six">
          <tichengTopcontent2></tichengTopcontent2>
          <tichengList2></tichengList2>
        </el-tab-pane>
        <el-tab-pane label="固定工资" name="third"></el-tab-pane>
        <el-tab-pane label="员工奖惩" name="fourth"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import list from './cpns/list/list.vue'
import topContent from './cpns/top-content/top-content.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import tichengTopcontent from './cpns/ticheng-topcontent/ticheng-topcontent.vue'
import tichengTopcontent2 from './cpns/ticheng-topcontent2/ticheng-topcontent.vue'
import tichengList from './cpns/ticheng-list/ticheng-list.vue'
import tichengList2 from './cpns/ticheng-list2/ticheng-list.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    list,
    Breadcrumb,
    topContent,
    tichengTopcontent,
    tichengList,
    tichengList2,
    tichengTopcontent2
  },
  setup() {
    const route = useRoute()
    const activeName = ref('first')


    // const initPathStr = (arr) => {
    //   let pathList = []
    //   let pathStr=''
    //   arr.forEach((item,index) => {
    //     let str
    //     pathStr+=`/${item}`
    //     switch (item) {
    //       case 'main':
    //         str = '首页'
    //         break;
    //       case 'score':
    //         str = '提成'
    //         break;
    //       case 'scoreRuleList':
    //         str = '提成列表'
    //         break;
    //     }
    //     pathList.push({
    //       name: str,
    //       path:pathStr
    //     })
    //   })
    //   return pathList
    // }

    // const getPath = () => {
    //   let pathArr = route.path.split('/')
    //   pathArr.splice(0,1)
    //   let pathList=initPathStr(pathArr)
    //   // console.log(pathList);
    // }
    // getPath()


    return {
      breadcrumbList,
      activeName
    }

  }
})
</script>

<style scoped lang="less">
.rule-lsit {
  .main-content {
    min-width: 1080px;
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>
