<template>
  <div class="ticheng-list">
    <page-content
      :dataList="dataList"
      :contentTableConfig="contentTableConfig2"
      :isButtonStyle="true"
    >
      <template #handler="scope">
        <div class="handle-btns">
          <el-button @click="handleItemClick(scope.row)" plain size="mini" v-has="'shop:person:check'"
            >详情</el-button
          >
          <el-button @click="handleUpdateClick(scope.row)" plain size="mini" v-has="'shop:person:edit'"
            >编辑</el-button
          >
          <el-button @click="handleRemoveClick(scope.row)" plain size="mini" v-has="'shop:person:delete'"
            >删除</el-button
          >
          <el-button @click="handleFenpeiClick(scope.row)" plain size="mini"
            >分配</el-button
          >
        </div>
      </template>
      <template #type="scope">
        <template v-if="scope.row.type == 1"> 服务提成 </template>
        <template v-if="scope.row.type == 2"> 卡项提成 </template>
      </template>
      <template #pagination>
        <el-pagination v-model:currentPage="pageFrom.current" v-model:page-size="pageFrom.size"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </template>
    </page-content>

    <roleDistribute
      @refreshList="refreshList"
      v-if="dialogRoleVisible"
      :ruleId="curretTid"
      :ruleKind="currentRuleKind"
      @changeDialogVisible="changeRoleDialogVisible"
      :dialogVisible="dialogRoleVisible"
    ></roleDistribute>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref ,watch} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig2 } from '../../config'
import { getTichengList, removeTichengRule } from '@/service/main/score'
import { COMPANY_ID } from '@/common/constant'
import { ElMessage } from 'element-plus'
import roleDistribute from './cpns/role-distribute-dialog/role-distribute-dialog.vue'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'

export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    roleDistribute
  },
  setup() {
    const router = useRouter()
    const dataList = ref([])
    const dataCount = ref(0)

    const handleRemoveClick = async (item) => {
      popup('确认删除该方案吗?', async() => {
        const res = await removeTichengRule(item.id)
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          initPage()
        } else {
          ElMessage({
            message: '规则使用中,无法删除!',
            type: 'warning',
          })
        }
      })
    }

    const curretTid = ref(-1)
    const refreshList = () => {
      initPage()
    }
    const dialogRoleVisible = ref(false)
    const changeRoleDialogVisible = flag => {
      dialogRoleVisible.value = flag
    }

    const currentRuleKind=ref()
    const handleFenpeiClick = (item) => {
      // console.log(item);
      curretTid.value = item.id
      currentRuleKind.value=item.type
      dialogRoleVisible.value = true
    }

    const handleUpdateClick = (item) => {
      router.push({
        path: '/main/score/scoreEditTicheng',
        query: {
          isview: false,
          type: item.type,
          ruleId: item.id
        }
      })
      // console.log(item);
    }

    const handleItemClick = (item) => {
      router.push({
        path: '/main/score/scoreEditTicheng',
        query: {
          isview: true,
          type: item.type,
          ruleId: item.id
        }
      })
    }
    const handleSizeChange = pagesize => {
      pageFrom.size = pagesize
    }

    const handleCurrentChange = page => {
      pageFrom.current = page
    }


    const pageFrom = reactive({
      current:1,
      size:10
    })
    
    watch(pageFrom, () => {
      initPage()
    }, {
      deep: true
    })

    const initPage = async () => {
      const res = await getTichengList(pageFrom)
      dataList.value = res.data.list
      dataCount.value=Number(res.data.total)
    }
    initPage()

    return {
      currentRuleKind,
      handleItemClick,
      handleUpdateClick,
      handleFenpeiClick,
      changeRoleDialogVisible,
      curretTid,
      dialogRoleVisible,
      refreshList,
      handleRemoveClick,
      dataList,
      contentTableConfig2,
      pageFrom,
      dataCount,
      handleSizeChange,
      handleCurrentChange,
    }

  }
})
</script>

<style scoped lang="less">
.ticheng-list {
  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }
}
</style>
